"use client";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { AiFillMail, AiFillLock } from "react-icons/ai";
import { useFormState } from "react-dom";
import { login } from "../../_actions/login";

const initialState = {
  message: "",
};

export default function LoginForm() {
  const [state, formAction] = useFormState(login, initialState);
  return (
    <form className="login-form" action={formAction}>
      <div className="login-header">
        <Image src="/logo.svg" alt="logo" width={50} height={50} />
        <h2>Customer Portal Login </h2>
        <p>Enter your credential to login.</p>
        <p className="login-message">{state?.message}</p>
      </div>
      <div className="login-input-wrapper">
        <div className="login-input-icon">
          <AiFillMail />
        </div>
        <input
          id="email"
          name="email"
          type="email"
          placeholder="youremail@theadress.com"
        />
      </div>
      <div className="login-input-wrapper">
        <div className="login-input-icon">
          <AiFillLock />
        </div>
        <input
          id="password"
          name="password"
          type="password"
          placeholder="Password"
        />
      </div>
      <div className="login-button-wrapper">
        <button className="login-btn">Login</button>
      </div>
      <div className="login-helper">
        <Link href="/recover-password">Forgot Password?</Link>
      </div>
    </form>
  );
}
